<template>
  <div id="app">
    <b-container>
      <b-row>
        <b-col>
          <Stepper />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import Color from "color";

import { SET_LANGUAGE } from "@/constants/mutations";

import Stepper from "./components/Stepper.vue";
import getParams from "./params";
import { getLocale } from "./locale";

const currentLocale = getLocale();
const shortLocale = currentLocale.slice(0, 2);
const lang = currentLocale != 'nl-BE' ? shortLocale : currentLocale;

export default {
  name: "App",
  components: {
    Stepper,
  },
  mounted: function () {
    this.$store.commit(SET_LANGUAGE, lang);

    document.documentElement.lang = shortLocale;

    this.updateButtonColor();
    this.updateInitialButtonColor();
    this.updateActiveColor();
    this.updateTextColor();
  },
  methods: {
    updateCSSVariable(name, value) {
      this.$el.style.setProperty(name, value);
    },
    updateButtonColor() {
      const { buttonColor } = getParams();

      if (buttonColor) {
        this.updateCSSVariable("--button-color", Color(`#${buttonColor}`).hsl().string());
        this.updateCSSVariable("--button-color-hover", Color(`#${buttonColor}`).darken(0.075).hsl().string());
        this.updateCSSVariable("--button-color-hover-border", Color(`#${buttonColor}`).darken(0.1).hsl().string());
        this.updateCSSVariable("--button-color-active", Color(`#${buttonColor}`).darken(0.1).hsl().string());
        this.updateCSSVariable("--button-color-active-border", Color(`#${buttonColor}`).darken(0.125).hsl().string());
        this.updateCSSVariable(
          "--button-color-outline",
          Color(`#${buttonColor}`)
            .mix(this.colorYiq(`#${buttonColor}`), 0.15)
            .alpha(0.5)
            .hsl()
            .string()
        );
      }
    },
    updateInitialButtonColor() {
      const { buttonColor } = getParams();

      if (buttonColor) {
        this.updateCSSVariable("--initial-button-color", Color(`#${buttonColor}`).hsl().string());
        this.updateCSSVariable("--initial-button-color-hover", Color(`#${buttonColor}`).darken(0.075).hsl().string());
        this.updateCSSVariable(
          "--initial-button-color-hover-border",
          Color(`#${buttonColor}`).darken(0.1).hsl().string()
        );
        this.updateCSSVariable("--initial-button-color-active", Color(`#${buttonColor}`).darken(0.1).hsl().string());
        this.updateCSSVariable("--button-color-active-border", Color(`#${buttonColor}`).darken(0.125).hsl().string());
        this.updateCSSVariable(
          "--initial-button-color-outline",
          Color(`#${buttonColor}`)
            .mix(this.colorYiq(`#${buttonColor}`), 0.15)
            .alpha(0.5)
            .hsl()
            .string()
        );
      }
    },
    colorYiq(color) {
      return Color(color).isDark ? Color("white") : Color("#212529");
    },
    updateActiveColor() {
      const { activeColor } = getParams();

      if (activeColor) {
        this.updateCSSVariable("--active-color", Color(`#${activeColor}`).hsl().string());
        this.updateCSSVariable("--active-color-hover", Color(`#${activeColor}`).darken(0.15).hsl().string());
        this.updateCSSVariable("--active-color-outline", Color(`#${activeColor}`).alpha(0.25).hsl().string());
        this.updateCSSVariable("--active-color-radio-focus", Color(`#${activeColor}`).lighten(0.25).hsl().string());
        this.updateCSSVariable("--active-color-focus", Color(`#${activeColor}`).alpha(0.25).hsl().string());
        this.updateCSSVariable("--active-color-active", Color(`#${activeColor}`).lighten(0.35).hsl().string());
      }
    },
    updateTextColor() {
      const { textColor } = getParams();

      if (textColor) {
        this.updateCSSVariable("--text-color", `#${textColor}`);
      }
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/fisher.scss";
</style>
