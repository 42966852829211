export default function () {
  const getParameterByName = (name, url) => {
    if (!url) {
      url = window.location.href;
    }

    name = name.replace(/[[\]]/g, "\\$&");
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);

    if (!results) {
      return null;
    }

    if (!results[2]) {
      return "";
    }

    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  const consentGrantedParameter = getParameterByName("consent-granted");

  return {
    apiKey: getParameterByName("api-key"),
    lang: getParameterByName("lang"),
    buttonColor: getParameterByName("button-color"),
    activeColor: getParameterByName("active-color"),
    textColor: getParameterByName("text-color"),
    gaTrackingId: getParameterByName("ga-tracking-id"),
    gaClientId: getParameterByName("ga-client-id"),
    customField: getParameterByName("custom"),
    parent: getParameterByName("parent"),
    consentGranted: consentGrantedParameter === "true" || !consentGrantedParameter,
    gtmId: getParameterByName("gtm-id"),
  };
}
