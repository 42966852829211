import axios from "axios";
import params from "@/params";
import { CREATE_VALUATION_URL, COMMON_SETTING_URL } from "@/constants/urls";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  crossDomain: true,
  params: {
    "api-key": params().apiKey,
  },
});

export function createValuation(data) {
  return instance.post(CREATE_VALUATION_URL, data);
}

export function getCommonSettings() {
  return instance.get(COMMON_SETTING_URL);
}
