export const countries = {
  CH: {
    coordinates: {
      lat: 47.3784,
      lng: 8.5384,
    },
  },
  CZ: {
    coordinates: {
      lat: 50.0942,
      lng: 14.4872,
    },
  },
  FR: {
    coordinates: {
      lat: 48.8768,
      lng: 2.3593,
    },
  },
  DE: {
    coordinates: {
      lat: 52.5251,
      lng: 13.3694,
    },
  },
  JP: {
    coordinates: {
      lat: 35.6814,
      lng: 139.7663,
    },
  },
  AT: {
    coordinates: {
      lat: 48.1852,
      lng: 16.3766,
    },
  },
  NL: {
    coordinates: {
      lat: 52.374,
      lng: 4.8896,
    },
  },
  BE: {
    coordinates: {
      lat: 50.847,
      lng: 4.392,
    },
  },
  SK: {
    coordinates: {
      lat: 48.156,
      lng: 17.1065,
    },
  },
};
