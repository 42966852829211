import * as Vuex from "vuex";
import { event, set } from "vue-gtag";
import { debounce } from "lodash";
import params from "@/params";

// to avoid spaming events with the sliders
const newEvent = debounce((category, action) => event(action, { event_category: category }), 1000);

import {
  SHOW_STEPPER,
  SWITCH_TO_STEP,
  SELECT_PROPERTY_TYPE,
  SELECT_HOUSE_SUBTYPE,
  SELECT_APARTMENT_SUBTYPE,
  SET_LIVING_AREA_SQM,
  SET_LAND_AREA_SQM,
  SET_BUILDING_YEAR,
  SET_NUMBER_OF_ROOMS,
  SET_NUMBER_OF_BEDROOMS,
  SELECT_CONDITION,
  SET_NUMBER_OF_BATHROOMS,
  SET_NUMBER_OF_UNITS,
  SET_HAS_GARAGE,
  SET_HAS_PARKING_SPACE,
  SET_HAS_OPEN_KITCHEN,
  SET_ADDRESS,
  SELECT_SALUTATION,
  SET_PHONE,
  SET_FIRST_NAME,
  SET_LAST_NAME,
  SET_EMAIL,
  SET_REVOCATION_EMAIL,
  SET_REASON,
  SET_SELLING_URGENCY,
  SET_PROPERTY_PRICE,
  SET_PRIVACY_POLICY_URL,
  SET_PRIVACY_POLICY_URL_PH,
  SET_CONSENT_TEXT,
  SET_COUNTRY,
  SET_CURRENCY,
  SET_DISPLAY_NAME,
  SET_LEAD_EMAIL,
  SET_AGREED_TO_CUSTOMER_PRIVACY_POLICY,
  SET_LANGUAGE,
  SET_PHONE_REQUIRED_VALIDATION,
  SET_ASK_FOR_LAST_KNOWN_SALE_PRICE,
  INVALID_API_KEY,
  EMPTY_API_KEY,
} from "@/constants/mutations";
import { REQUEST_VALUATION, GET_COMMON_SETTINGS } from "@/constants/actions";
import { COUNTRY_CODES } from "@/constants/country_codes";

import { FIRST, SECOND, THIRD, FOURTH, FIFTH, FINAL } from "@/constants/steps";

import { APARTMENT, HOUSE, MULTI_FAMILY_HOUSE } from "@/constants/property_codes";
import { APARTMENT_NORMAL, HOUSE_DETACHED } from "@/constants/property_subcodes";

import { SELL } from "@/constants/reasons";

import { PH_PRIVACY_POLICY_URL } from "@/constants/urls";
import { createValuation, getCommonSettings } from "@/api";

import { parseAddress } from "@/address";
import { getLocale } from "@/locale";
import getParams from "@/params";
import { countries } from "./constants/countries";

function boolToInt(value) {
  if (value === true) {
    return 1;
  } else if (value === false) {
    return 0;
  } else {
    return null;
  }
}

export default Vuex.createStore({
  state: {
    stepperShown: false,
    activeStep: FIRST,

    propertyType: APARTMENT,
    apartmentSubtype: APARTMENT_NORMAL,
    houseSubtype: HOUSE_DETACHED,

    livingAreaSqm: 50,
    landAreaSqm: 400,
    buildingYear: 2000,
    numberOfRooms: 2,
    numberOfBedrooms: 2,
    numberOfUnits: 3,
    condition: null,

    numberOfBathrooms: 1,
    hasGarage: null,
    hasParkingSpace: null,
    hasOpenKitchen: true,

    address: null,

    salutation: "",
    phone: "",
    firstName: "",
    lastName: "",
    email: "",
    revocationEmail: "",
    reason: "",
    sellingUrgency: "",
    propertyPrice: 1000000,
    privacyPolicyURL: "",
    displayName: "",
    leadEmail: "",
    country: "",
    currency: "",
    agreedToCustomerPrivacyPolicy: false,
    isPhoneRequired: false,
    isLastKnownSalePriceAsked: true,

    isApiKeyEmpty: false,
    isApiKeyInvalid: false,

    lang: "en",
    customField: "",
  },
  mutations: {
    [SHOW_STEPPER](state) {
      state.stepperShown = true;
    },
    [SWITCH_TO_STEP](state, step) {
      state.activeStep = step;
    },

    [SELECT_PROPERTY_TYPE](state, propertyType) {
      newEvent("SELECT_PROPERTY_TYPE", "set");
      state.propertyType = propertyType;
    },
    [SELECT_HOUSE_SUBTYPE](state, houseSubtype) {
      newEvent("SELECT_HOUSE_SUBTYPE", "set");
      state.houseSubtype = houseSubtype;
    },
    [SELECT_APARTMENT_SUBTYPE](state, apartmentSubtype) {
      newEvent("SELECT_APARTMENT_SUBTYPE", "set");
      state.apartmentSubtype = apartmentSubtype;
    },

    [SET_LIVING_AREA_SQM](state, livingAreaSqm) {
      newEvent("SET_LIVING_AREA_SQM", "set");
      state.livingAreaSqm = livingAreaSqm;
    },
    [SET_LAND_AREA_SQM](state, landAreaSqm) {
      newEvent("SET_LAND_AREA_SQM", "set");
      state.landAreaSqm = landAreaSqm;
    },
    [SET_BUILDING_YEAR](state, buildingYear) {
      newEvent("SET_BUILDING_YEAR", "set");
      state.buildingYear = buildingYear;
    },
    [SET_NUMBER_OF_ROOMS](state, numberOfRooms) {
      newEvent("SET_NUMBER_OF_ROOMS", "set");
      state.numberOfRooms = numberOfRooms;
    },
    [SET_NUMBER_OF_BEDROOMS](state, numberOfBedrooms) {
      newEvent("SET_NUMBER_OF_BEDROOMS", "set");
      state.numberOfBedrooms = numberOfBedrooms;
    },
    [SET_NUMBER_OF_UNITS](state, numberOfUnits) {
      newEvent("SET_NUMBER_OF_UNITS", "set");
      state.numberOfUnits = numberOfUnits;
    },
    [SELECT_CONDITION](state, condition) {
      newEvent("SELECT_CONDITION", "set");
      state.condition = condition;
    },

    [SET_NUMBER_OF_BATHROOMS](state, numberOfBathrooms) {
      newEvent("SET_NUMBER_OF_BATHROOMS", "set");
      state.numberOfBathrooms = numberOfBathrooms;
    },
    [SET_HAS_GARAGE](state, hasGarage) {
      newEvent("SET_HAS_GARAGE", "set");
      state.hasGarage = hasGarage;
    },
    [SET_HAS_PARKING_SPACE](state, hasParkingSpace) {
      newEvent("SET_HAS_PARKING_SPACE", "set");
      state.hasParkingSpace = hasParkingSpace;
    },
    [SET_HAS_OPEN_KITCHEN](state, hasOpenKitchen) {
      newEvent("SET_HAS_OPEN_KITCHEN", "set");
      state.hasOpenKitchen = hasOpenKitchen;
    },

    [SET_ADDRESS](state, address) {
      newEvent("SET_ADDRESS", "set");
      state.address = address;
    },

    [SELECT_SALUTATION](state, salutation) {
      newEvent("SELECT_SALUTATION", "set");
      state.salutation = salutation;
    },
    [SET_PHONE](state, phone) {
      newEvent("SET_PHONE", "set");
      state.phone = phone;
    },
    [SET_FIRST_NAME](state, firstName) {
      newEvent("SET_FIRST_NAME", "set");
      state.firstName = firstName;
    },
    [SET_LAST_NAME](state, lastName) {
      newEvent("SET_LAST_NAME", "set");
      state.lastName = lastName;
    },
    [SET_EMAIL](state, email) {
      newEvent("SET_EMAIL", "set");
      state.email = email;
    },
    [SET_REVOCATION_EMAIL](state, revocationEmail) {
      state.revocationEmail = revocationEmail;
    },
    [SET_REASON](state, reason) {
      newEvent("SET_REASON", "set");
      state.reason = reason;
    },
    [SET_SELLING_URGENCY](state, sellingUrgency) {
      newEvent("SET_SELLING_URGENCY", "set");
      state.sellingUrgency = sellingUrgency;
    },
    [SET_PROPERTY_PRICE](state, propertyPrice) {
      newEvent("SET_PROPERTY_PRICE", "set");
      state.propertyPrice = propertyPrice;
    },
    [SET_PRIVACY_POLICY_URL](state, privacyPolicyURL) {
      state.privacyPolicyURL = privacyPolicyURL;
    },
    [SET_PRIVACY_POLICY_URL_PH](state, privacyPolicyURLPH) {
      state.privacyPolicyURLPH = privacyPolicyURLPH;
    },
    [SET_CONSENT_TEXT](state, consentText) {
      state.consentText = consentText;
    },
    [SET_COUNTRY](state, country) {
      state.country = country;
    },
    [SET_CURRENCY](state, currency) {
      state.currency = currency;
    },
    [SET_DISPLAY_NAME](state, displayName) {
      state.displayName = displayName;
    },
    [SET_LEAD_EMAIL](state, leadEmail) {
      state.leadEmail = leadEmail;
    },
    [SET_AGREED_TO_CUSTOMER_PRIVACY_POLICY](state, agreed) {
      newEvent("SET_AGREED_TO_CUSTOMER_PRIVACY_POLICY", "set");
      state.agreedToCustomerPrivacyPolicy = agreed;
    },

    [SET_PHONE_REQUIRED_VALIDATION](state, isRequired) {
      state.isPhoneRequired = isRequired;
    },

    [SET_ASK_FOR_LAST_KNOWN_SALE_PRICE](state, isAsked) {
      state.isLastKnownSalePriceAsked = isAsked;
    },

    [SET_LANGUAGE](state, lang) {
      state.lang = lang;
    },

    [INVALID_API_KEY](state, isError) {
      state.isApiKeyInvalid = isError;
    },
    [EMPTY_API_KEY](state, isEmpty) {
      state.isApiKeyEmpty = isEmpty;
    },
  },
  getters: {
    firstStep: (state) => state.activeStep === FIRST,
    secondStep: (state) => state.activeStep === SECOND,
    thirdStep: (state) => state.activeStep === THIRD,
    fourthStep: (state) => state.activeStep === FOURTH,
    fifthStep: (state) => state.activeStep === FIFTH,
    finalStep: (state) => state.activeStep === FINAL,

    isApiKeyEmpty: (state) => state.isApiKeyEmpty,
    isApiKeyInvalid: (state) => state.isApiKeyInvalid,

    apartmentSelected: (state) => state.propertyType === APARTMENT,
    houseSelected: (state) => state.propertyType === HOUSE,
    isMultiFamilyHouseSelected: (state) => state.propertyType === MULTI_FAMILY_HOUSE,
    propertySubtype: (state, getters) => {
      if (getters.houseSelected) {
        return state.houseSubtype;
      }
      return state.apartmentSubtype;
    },

    coordinates: (state) => {
      const country = countries[state.country] || countries[COUNTRY_CODES.SWITZERLAND];

      if (state.address) {
        const location = state.address.geometry.location;
        return {
          lat: location.lat(),
          lng: location.lng(),
        };
      } else {
        return country.coordinates;
      }
    },
    formattedAddress: (state) => {
      if (state.address) {
        return state.address.formatted_address;
      } else {
        return "";
      }
    },
    parsedAddress: (state) => {
      let address = null;

      if (state.address && state.address.address_components) {
        address = parseAddress(state.address.address_components, state.address.adr_address, state.country);
      }

      return address;
    },

    sellSelected: (state) => state.reason === SELL,
    emailHref: (state) => (state.email ? "mailto:" + state.email : ""),

    data: (state, getters) => {
      const coordinates = getters.coordinates;
      const address = getters.parsedAddress;
      const params = getParams();

      let data = {
        email: state.email,
        revocationEmail: state.revocationEmail,
        salutation: state.salutation,
        first_name: state.firstName,
        last_name: state.lastName,
        phone_number: state.phone,
        reason: state.reason,
        code: state.propertyType,
        living_area_sqm: state.livingAreaSqm,
        building_year: state.buildingYear,
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        has_garage: boolToInt(state.hasGarage),
        has_parking_space: boolToInt(state.hasParkingSpace),
        locale: getLocale(),
        lang: state.lang,
        custom_field: params.customField,
      };

      if (
        ![
          COUNTRY_CODES.CZECHIA,
          COUNTRY_CODES.AUSTRIA,
          COUNTRY_CODES.JAPAN,
          COUNTRY_CODES.FRANCE,
          COUNTRY_CODES.SLOVAKIA,
        ].includes(state.country) &&
        state.propertyType !== MULTI_FAMILY_HOUSE
      ) {
        data.subcode = getters.propertySubtype;
      }

      if (state.propertyType !== MULTI_FAMILY_HOUSE) {
        data.number_of_bathrooms = state.numberOfBathrooms;
        data.condition = state.condition;
        if (state.country !== COUNTRY_CODES.BELGIUM) {
          data.number_of_rooms = state.numberOfRooms;
        } else {
          data.number_of_bedrooms = state.numberOfBedrooms;
        }
      } else {
        data.number_of_units = state.numberOfUnits;
      }

      if (state.country === COUNTRY_CODES.CZECHIA) {
        data.has_open_kitchen = state.hasOpenKitchen || false;
      }

      if (address) {
        data = { ...data, ...address };
      }

      if (getters.houseSelected) {
        data = { ...data, land_area_sqm: state.landAreaSqm };
      }

      if (params.buttonColor) {
        data.button_color = `#${params.buttonColor}`;
      }

      if (data.reason === SELL) {
        data = { ...data, selling_urgency: state.sellingUrgency };
        if (state.isLastKnownSalePriceAsked === true) {
          data = { ...data, paid_for_property: state.propertyPrice };
        }
      }

      Object.keys(data).forEach((key) => data[key] === null && delete data[key]);

      return data;
    },
  },
  actions: {
    [GET_COMMON_SETTINGS]({ commit }) {
      const apiKey = params().apiKey;

      commit(EMPTY_API_KEY, !apiKey);

      if (!apiKey) {
        return;
      }

      getCommonSettings()
        .then((response) => {
          if (response.data.privacy_policy_url) {
            commit(SET_PRIVACY_POLICY_URL, response.data.privacy_policy_url);
          }

          if (response.data.consent_text) {
            commit(SET_CONSENT_TEXT, response.data.consent_text);
          }

          commit(SET_PRIVACY_POLICY_URL_PH, PH_PRIVACY_POLICY_URL);
          commit(SET_COUNTRY, response.data.country);
          commit(SET_CURRENCY, response.data.currency);
          commit(SET_DISPLAY_NAME, response.data.display_name);
          commit(SET_LEAD_EMAIL, response.data.email);
          commit(SET_REVOCATION_EMAIL, response.data.revocation_email);
          commit(SET_PHONE_REQUIRED_VALIDATION, response.data.require_phone_number);
          commit(SET_ASK_FOR_LAST_KNOWN_SALE_PRICE, response.data.ask_for_last_known_sale_price);

          commit(INVALID_API_KEY, false);

          set({ dimension1: response.data.display_name });
          set({ dimension2: params().parent });
          set({ dimension3: params().customField });
        })
        .catch((_error) => {
          commit(INVALID_API_KEY, true);
        });
    },
    [REQUEST_VALUATION]({ commit, getters }) {
      return createValuation(getters.data)
        .then((response) => {
          newEvent("VALUATION_REQUEST", "sent");
          commit(SWITCH_TO_STEP, FINAL);
        })
        .catch((error) => {
          newEvent("VALUATION_REQUEST", "failed");
          // TODO: add error handling (FE-1013)
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
          } else {
            // Something happened in setting up the request that triggered an Error
          }
        });
    },
  },
});
