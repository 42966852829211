export const APARTMENT_NORMAL = "apartment_normal";
export const APARTMENT_MAISONETTE = "apartment_maisonette";
export const APARTMENT_ATTIC = "apartment_attic";
export const APARTMENT_PENTHOUSE = "apartment_penthouse";
export const APARTMENT_TERRACED = "apartment_terraced";
export const APARTMENT_STUDIO = "apartment_studio";

export const HOUSE_DETACHED = "house_detached";
export const HOUSE_SEMI_DETACHED = "house_semi_detached";
export const HOUSE_ROW_CORNER = "house_row_corner";
export const HOUSE_ROW_MIDDLE = "house_row_middle";
