<template>
  <div v-if="isApiKeyEmpty || isApiKeyInvalid || country" class="mt-4">
    <h5 v-if="isApiKeyEmpty" class="top-error">
      {{ $t("input.errors.no_api_key") }}
    </h5>
    <h5 v-if="isApiKeyInvalid" class="top-error">
      {{ $t("input.errors.invalid_api_key") }}
    </h5>
    <FirstStep v-if="firstStep" />
    <SecondStep v-if="secondStep" :is-shown="secondStep" />
    <ThirdStep v-if="thirdStep" :is-shown="thirdStep" />
    <FourthStep v-if="fourthStep" />
    <FifthStep v-if="fifthStep" :is-shown="fifthStep" />
    <FinalStep v-if="finalStep" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { defineAsyncComponent } from "vue";

export default {
  name: "StepperWrapper",
  components: {
    FirstStep: defineAsyncComponent(() => import("./steps/FirstStep.vue")),
    SecondStep: defineAsyncComponent(() => import("./steps/SecondStep.vue")),
    ThirdStep: defineAsyncComponent(() => import("./steps/ThirdStep.vue")),
    FourthStep: defineAsyncComponent(() => import("./steps/FourthStep.vue")),
    FifthStep: defineAsyncComponent(() => import("./steps/FifthStep.vue")),
    FinalStep: defineAsyncComponent(() => import("./steps/FinalStep.vue")),
  },
  computed: {
    ...mapGetters([
      "firstStep",
      "secondStep",
      "thirdStep",
      "fourthStep",
      "fifthStep",
      "finalStep",
      "isApiKeyEmpty",
      "isApiKeyInvalid",
    ]),
    ...mapState(["country"]),
  },
  mounted() {
    this.$ga && this.$ga.page("first");
  },
};
// Prevents google fonts from being injected by google maps
const head = document.getElementsByTagName("head")[0];
const insertBefore = head.insertBefore;
head.insertBefore = function (newElement, referenceElement) {
  if (newElement.href && newElement.href.includes("fonts.googleapis.com/css?family")) {
    return;
  }
  insertBefore.call(head, newElement, referenceElement);
};
</script>
