export const COUNTRY_CODES = {
  AUSTRIA: "AT",
  BELGIUM: "BE",
  CZECHIA: "CZ",
  FRANCE: "FR",
  GERMANY: "DE",
  JAPAN: "JP",
  NETHERLAND: "NL",
  SLOVAKIA: "SK",
  SWITZERLAND: "CH",
};
