export const BUY = "buy";
export const SELL = "sell";
export const JUST_LOOKING = "just_looking";
export const OTHER = "other";
export const REASON_OPTIONS = [
  {
    text: "common.valuation_reasons.buy",
    value: BUY,
  },
  {
    text: "common.valuation_reasons.sell",
    value: SELL,
  },
  {
    text: "common.valuation_reasons.just_looking",
    value: JUST_LOOKING,
  },
  {
    text: "common.valuation_reasons.other",
    value: OTHER,
  },
];
