const messages = {
  cs: require("./i18n/cs.json"),
  "cs-CZ": require("./i18n/cs.json"),
  "en-GB": require("./i18n/en.json"),
  "en-US": require("./i18n/en.json"),
  en: require("./i18n/en.json"),
  de: require("./i18n/de.json"),
  "de-DE": require("./i18n/de.json"),
  "de-CH": require("./i18n/de.json"),
  fr: require("./i18n/fr.json"),
  "fr-FR": require("./i18n/fr.json"),
  "fr-CH": require("./i18n/fr.json"),
  it: require("./i18n/it.json"),
  "it-IT": require("./i18n/it.json"),
  "it-CH": require("./i18n/it.json"),
  jp: require("./i18n/ja.json"),
  "jp-JP": require("./i18n/ja.json"),
  nl: require("./i18n/nl.json"),
  "nl-NL": require("./i18n/nl.json"),
  "nl-BE": require("./i18n/nl-BE.json"),
  sk: require("./i18n/sk.json"),
  "sk-SK": require("./i18n/sk.json"),
};

export default messages;
