export const SHOW_STEPPER = "showStepper";
export const SWITCH_TO_STEP = "switchToStep";

export const SELECT_PROPERTY_TYPE = "selectPropertyType";
export const SELECT_HOUSE_SUBTYPE = "selectHouseSubtype";
export const SELECT_APARTMENT_SUBTYPE = "selectApartmentSubtype";

export const SET_LIVING_AREA_SQM = "setLivingAreaSqm";
export const SET_LAND_AREA_SQM = "setLandAreaSqm";
export const SET_BUILDING_YEAR = "setBuildingYear";
export const SET_NUMBER_OF_ROOMS = "setNumberOfRooms";
export const SET_NUMBER_OF_BEDROOMS = "setNumberOfBedrooms";
export const SET_NUMBER_OF_UNITS = "setnumberOfUnits";
export const SELECT_CONDITION = "selectCondition";

export const SET_NUMBER_OF_BATHROOMS = "setNumberOfBathrooms";
export const SET_HAS_GARAGE = "setHasGarage";
export const SET_HAS_PARKING_SPACE = "setHasParkingSpace";
export const SET_HAS_OPEN_KITCHEN = "setHasOpenKitchen";

export const SET_ADDRESS = "setAddress";

export const SELECT_SALUTATION = "selectSalutation";
export const SET_PHONE = "setPhone";
export const SET_FIRST_NAME = "setFirstName";
export const SET_LAST_NAME = "setLastName";
export const SET_EMAIL = "setEmail";
export const SET_REVOCATION_EMAIL = "setRevocationEmail";
export const SET_REASON = "setReason";
export const SET_SELLING_URGENCY = "setSellingUrgency";
export const SET_PROPERTY_PRICE = "setPropertyPrice";
export const SET_PRIVACY_POLICY_URL = "setPrivacyPolicyURL";
export const SET_PRIVACY_POLICY_URL_PH = "setPrivacyPolicyURLPH";
export const SET_COUNTRY = "setCountry";
export const SET_CURRENCY = "setCurrency";
export const SET_DISPLAY_NAME = "setDisplayName";
export const SET_LEAD_EMAIL = "setLeadEmail";
export const SET_AGREED_TO_CUSTOMER_PRIVACY_POLICY = "setAgreedToCustomerPrivacyPolicy";
export const SET_CONSENT_TEXT = "setConsentText";

export const SET_PHONE_REQUIRED_VALIDATION = "isPhoneRequired";
export const SET_ASK_FOR_LAST_KNOWN_SALE_PRICE = "isLastKnownSalePriceAsked";

export const SET_LANGUAGE = "setLanguage";

// errors
export const INVALID_API_KEY = "invalidApiKey";
export const EMPTY_API_KEY = "emptyApiKey";
