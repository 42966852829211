import "iframe-resizer";

import BootstrapVue3 from "bootstrap-vue-3";
import cssVars from "css-vars-ponyfill";
import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import VueGtag from "vue-gtag";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import { createGtm } from "@gtm-support/vue-gtm";

import { GET_COMMON_SETTINGS } from "@/constants/actions";
import { SHORT_SUPPORTED_LANGUAGES } from "@/constants/language_codes";
import formHelpers from "@/shared/helpers/form-helpers";

import App from "./App.vue";
import { getLocale } from "./locale";
import messages from "./messages.js";
import params from "@/params";
import store from "./store";

cssVars();

const currentLocale = getLocale();
let shortLocale = currentLocale.slice(0, 2);

if (!SHORT_SUPPORTED_LANGUAGES.includes(shortLocale)) {
  shortLocale = "en";
}

const i18n = new createI18n({
  legacy: false,
  locale: currentLocale,
  fallbackLocale: shortLocale,
  globalInjection: true,
  messages,
});

const app = createApp(App);
app
  .use(i18n)
  .use(store)
  .use(BootstrapVue3)
  .use(VueGoogleMaps, {
    load: {
      language: shortLocale === "jp" ? "ja" : shortLocale,
      key: process.env.VUE_APP_GMAPS_API_KEY, // https://cli.vuejs.org/guide/mode-and-env.html#environment-variables
      libraries: "places", // This is required if you use the Autocomplete plugin
    },
  });

let GA_TRACKING_IDS = [];
let GA_CUSTOM_ID_FIELDS = {};
let query_params = params();
let analyticsTurnedOn = false;

if (process.env.VUE_APP_GA_UA) {
  GA_TRACKING_IDS.push(process.env.VUE_APP_GA_UA);
}

if (query_params.gaTrackingId) {
  GA_TRACKING_IDS.push(query_params.gaTrackingId);

  if (query_params.gaClientId) {
    // https://github.com/MatteoGabriele/vue-analytics/blob/master/docs/fields.md
    GA_CUSTOM_ID_FIELDS[query_params.gaTrackingId] = {
      clientId: query_params.gaClientId,
    };
  }
}

if (query_params.consentGranted !== false) {
  if (GA_TRACKING_IDS.length > 0) {
    enableAnalytics();
  }

  if (query_params.gtmId) {
    try {
      enableGTM();
    } catch (e) {
      throw new Error(e);
    }
  }
}

window.addEventListener("message", onMessage);

function onMessage(e) {
  if (e.data.consentGranted === true) {
    enableGTM();
    enableAnalytics();
  }
}

function enableGTM() {
  app.use(
    createGtm({
      id: query_params.gtmId,
      enabled: true,
    })
  );
}

function enableAnalytics() {
  if (analyticsTurnedOn) {
    return;
  }

  analyticsTurnedOn = true;

  const includes = GA_TRACKING_IDS[1]
    ? [
        {
          id: GA_TRACKING_IDS[1],
          params: {
            cookie_flags: "max-age=7200;secure;samesite=none",
            send_page_view: false,
          },
          fields: {
            customIdFields: GA_CUSTOM_ID_FIELDS,
          },
        },
      ]
    : [];

  app.use(VueGtag, {
    includes,
    config: {
      id: GA_TRACKING_IDS[0],
      params: {
        cookie_flags: "max-age=7200;secure;samesite=none",
        send_page_view: false,
      },
      fields: {
        customIdFields: GA_CUSTOM_ID_FIELDS,
      },
    },
  });
}

window.parent.postMessage(
  {
    widgetAppStarted: true,
  },
  "*"
);

app.mount("#app");

app.config.globalProperties.formHelpers = formHelpers;

app.config.globalProperties.routerAppend = (path, pathToAppend) => {
  return path + (path.endsWith("/") ? "" : "/") + pathToAppend;
};

store.dispatch(GET_COMMON_SETTINGS);
