
import { country_overseas } from "@/constants/countries_overseas";

export function findTypes(components, type) {
  return components.filter((component) => {
    return component.types.includes(type);
  });
}

function firstType(components, typeName, name) {
  const type = findTypes(components, typeName)[0];
  if (type && type[name]) {
    return type[name];
  }
}

export function parseAddress(addressComponents, addressString, country) {
  // Switzerland-specific parsing
  const houseNumber = firstType(addressComponents, "street_number", "short_name");
  const city =
    firstType(addressComponents, "locality", "long_name") || firstType(addressComponents, "sublocality", "long_name");
  let countryCode = firstType(addressComponents, "country", "short_name");
  const postCode = firstType(addressComponents, "postal_code", "long_name");
  let street = firstType(addressComponents, "route", "long_name");

  // as Japan has no street, we create one with a subset of the info here
  if (countryCode === "JP") {
    const route = [
      firstType(addressComponents, "sublocality_level_1", "short_name"),
      firstType(addressComponents, "sublocality_level_2", "short_name"),
      firstType(addressComponents, "sublocality_level_3", "short_name"),
      firstType(addressComponents, "sublocality_level_4", "short_name"),
      firstType(addressComponents, "premise", "short_name"),
      firstType(addressComponents, "subpremise", "short_name"),
    ];

    street = route.join(" ").trim();
  }
  if (!street && addressString) {
    street = addressString.match(/<span class="street-address">(.*?)<\/span>/i);
    street = street ? street[1] : undefined;
  }

  // Enforce supported country (usually from store) for overseas ones.
  const countries = getCountriesOverseas(country);
  if (country && country !== countryCode  && countries.indexOf(countryCode) > -1) {
    countryCode = country;
  }

  return {
    country_code: countryCode,
    city,
    post_code: postCode,
    street,
    house_number: houseNumber,
  };
}

export function getCountriesOverseas (countryCode) {
  const overseas = country_overseas[countryCode] || [];
  return [countryCode].concat(overseas);
}
