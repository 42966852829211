import getParams from "./params";

export function getLocale() {
  let lang = getParams().lang;

  if (["en", "de", "fr", "it", "jp"].includes(lang)) {
    return lang;
  }

  lang = lang || window.navigator.language || window.navigator.userLanguage;

  return lang === "ja" ? "jp" : lang;
}
